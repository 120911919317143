<template>
  <div>
    <!-- <img
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        z-index: -1;
      "
      src="../assets/image/homepage.jpg"
    /> -->
    <headPage />
    <!-- 生态水 -->
    <div class="box1" style="overflow: hidden">
      <div class="info">
        <div class="title1">还原每一滴生态水</div>
      </div>
    </div>
    <!-- 四重过滤 -->
    <div class="box2" style="overflow: hidden">
      <div class="info2">
        <div data-aos="fade-right">
          <div class="title2-1">四重过滤</div>
          <div class="title2-2">自来水秒变直饮水</div>
          <div class="title2-3">净水系统</div>
        </div>
        <img
          class="img1"
          style="display: block"
          src="../assets/image/waterPurification/image5.png"
        />
        <div class="select">
          <img
            class="img2"
            style="display: block"
            src="../assets/image/waterPurification/image9.png"
          />

          <div class="bg1">
            <p>前置<br />过滤器</p>
          </div>
        </div>
        <div class="select2">
          <img
            class="img3"
            style="display: block"
            src="../assets/image/waterPurification/image10.png"
          />
          <div class="bg2">
            <p>中央<br />软水机</p>
          </div>
        </div>

        <div class="select3">
          <img
            class="img4"
            style="display: block"
            src="../assets/image/waterPurification/image11.png"
          />
          <div class="bg4">
            <p>中央<br />净水器</p>
          </div>
        </div>
        <div class="select4">
          <img
            class="img5"
            style="display: block"
            src="../assets/image/waterPurification/image12.png"
          />
          <div class="bg3">
            <p>末端<br />直饮机</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 活水系统 -->
    <div class="box3" style="overflow: hidden">
      <div class="info3" data-aos="fade-right">
        <div class="title3">让管道不再是细菌繁殖的温床</div>
        <div class="title3-2">活水系统</div>
        <div class="line"></div>
        <div class="title3-3">
          MH享家打造全屋活水系统，确保水在管道内没有经过二次污染，全方位提高家庭用水品质。
        </div>
      </div>
    </div>
    <!-- 热水系统 -->
    <div class="box4" style="overflow: hidden">
      <div class="info3" data-aos="fade-right">
        <div class="title3">即开即热， 沐浴更舒畅</div>
        <div class="title3-2">热水系统</div>
        <div class="line"></div>
        <div class="title3-3">
          一年四季温度恒定，45~55°℃，满足多人用水需求，循环热水，节能，杜绝管道热损耗、即开即用，适用于大宅别墅。
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.select:hover {
  transform: scale(1.2);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .bg1 {
    top: 131px;
  }
}
.select {
  height: 328px;
  position: relative;
  top: -483px;
  width: 509px;
  left: 554px;
}
.select2 {
  height: 328px;
  position: relative;
  top: -809px;
  width: 509px;
  left: 1012px;
}
.select2:hover {
  transform: scale(1.2);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .bg2 {
    top: 131px;
  }
}
.select3 {
  height: 328px;
  position: relative;
  top: -807px;
  width: 509px;
  left: 655px;
}
.select4 {
  height: 328px;
  position: relative;
  top: -1112px;
  width: 509px;
  left: 1006px;
}
.select3:hover {
  transform: scale(1.2);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .bg4 {
    top: 131px;
  }
}
.select4:hover {
  transform: scale(1.2);
  // box-shadow: 0 0 2px 5px rgba(100, 100, 100, 0.07);
  z-index: 9999;
  .bg3 {
    top: 131px;
  }
}
.box1 {
  background-image: url(../assets/image/waterPurification/image2.png);
  background-size: cover;
  height: 637px;
}
.box2 {
  background-image: url(../assets/image/waterPurification/image4.jpg);
  background-size: cover;
  height: 731px;
  position: relative;
}
.box3 {
  background-image: url(../assets/image/waterPurification/image1.png);
  background-size: cover;
  height: 738px;
}
.box4 {
  background-image: url(../assets/image/waterPurification/image3.jpeg);
  background-size: cover;
  height: 710px;
  background-position-x: -14px;
  background-position-y: -837px;
  background-size: 117%;
  .title3 {
    margin-left: 130px;
    font-family: "SourceHanSansCN-Medium";
    color: #000;
    font-size: 56px;
    margin-top: 202px;
  }
  .title3-3 {
    width: 496px;
  }
}
.info {
  margin-top: 135px;
}
.title1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 65px;
  color: #d0ac32;
}
.info2 {
  text-align: left;
}
.title2-1 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 60px;
  color: #d0ac32;
  margin-left: 130px;
  margin-top: 242px;
}
.title2-2 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  margin-left: 129px;
  margin-top: -12px;
  font-size: 57px;
}
.title2-3 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  margin-left: 140px;
  font-size: 37px;
  margin-top: 24px;
}
.img1 {
  width: 50%;
  position: absolute;
  top: -18px;
  right: 113px;
}
.bg1 {
  position: absolute;
  height: 182px;
  width: 355px;
  top: 145px;
  right: 47px;
  background-color: #d0ac32;
  opacity: 0.7;
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 36px;
  p {
    margin-top: 44px;
    margin-left: 173px;
  }
}
.bg2 {
  position: absolute;
  height: 182px;
  width: 355px;
  top: 145px;
  right: 133px;
  font-family: "SourceHanSansCN-Medium";
  background-color: #d0ac32;
  opacity: 0.7;
  color: #000;
  font-size: 36px;
  p {
    margin-top: 44px;
    margin-left: 173px;
  }
}
.bg3 {
  position: absolute;
  height: 182px;
  width: 355px;
  top: 131px;
  right: 130px;
  background-color: #d0ac32;
  opacity: 0.7;
  color: #000;
  font-size: 36px;
  font-family: "SourceHanSansCN-Medium";
  p {
    margin-top: 44px;
    margin-left: 60px;
  }
}
.bg4 {
  position: absolute;
  height: 182px;
  width: 355px;
  top: 154px;
  right: 154px;
  background-color: #d0ac32;
  opacity: 0.7;
  color: #000;
  font-size: 36px;
  font-family: "SourceHanSansCN-Medium";
  p {
    margin-top: 44px;
    margin-left: 60px;
  }
}
.img2 {
  width: 60%;
  position: absolute;
  top: 11px;
  right: 173px;
  z-index: 999;
}
.img3 {
  width: 65%;
  position: absolute;
  top: 10px;
  right: 247px;
  z-index: 999;
}
.img4 {
  width: 64%;
  position: absolute;
  top: 28px;
  right: 95px;
  z-index: 999;
}
.img5 {
  width: 62%;
  position: absolute;
  top: 28px;
  right: 95px;
  z-index: 999;
}
.p1 {
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 36px;
}
.title2-4 {
  margin-left: 821px;
  margin-top: -314px;
}
.title2-5 {
  margin-left: 1214px;
  margin-top: -104px;
}
.title2-6 {
  margin-left: 738px;
  margin-top: 242px;
}
.title2-7 {
  margin-left: 1084px;
  margin-top: -103px;
}
.info3 {
  text-align: left;
}
.title3 {
  margin-left: 130px;
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 57px;
  margin-top: 127px;
}
.title3-2 {
  margin-left: 135px;
  font-family: "SourceHanSansCN-Medium";
  color: #000;
  font-size: 39px;
  margin-top: 6px;
}
.line {
  height: 2px;
  width: 59px;
  background-color: #000;
  margin-left: 139px;
  margin-top: 17px;
}
.title3-3 {
  font-family: "SourceHanSansCN-Light";
  color: #000;
  font-size: 16px;
  width: 457px;
  margin-top: 38px;
  margin-left: 138px;
}
</style>